import {
  calculateCompoundedRate,
  normalize,
  RAY_DECIMALS,
  SECONDS_PER_YEAR,
  USD_DECIMALS,
  valueToBigNumber
} from '@yldr/math-utils';
import { getBorrowRate } from './getBorrowRate';
import { ComputedReserveData } from '../hooks/app-data-provider/useAppDataProvider';
import BigNumber from 'bignumber.js';

export const getSupplyApy = ({
  amountToSupply,
  tokenToSupply,
}:{ amountToSupply: string; tokenToSupply: ComputedReserveData; }) => {
  if (!tokenToSupply) {
    return valueToBigNumber(0).toString();
  }

  const totalSuppliedReal = valueToBigNumber(amountToSupply || 0)
    .multipliedBy(
      valueToBigNumber(tokenToSupply.priceInMarketReferenceCurrency).div(
        BigNumber(10).pow(USD_DECIMALS)
      )
    );

  const totalLiquidity = valueToBigNumber(tokenToSupply.totalVariableDebtUSD);
  const totalLiquidityAfter = valueToBigNumber(tokenToSupply.totalLiquidityUSD).plus(totalSuppliedReal);
  const usageRatio = totalLiquidity.multipliedBy(10 ** 27).div(totalLiquidityAfter);
  const borrowRate = getBorrowRate(tokenToSupply, usageRatio);
  const liquidityRate = borrowRate
    .multipliedBy(usageRatio)
    .div(10 ** 27)
    .multipliedBy(BigNumber(1).minus(tokenToSupply.reserveFactor));

  return normalize(calculateCompoundedRate({
    rate: liquidityRate,
    duration: SECONDS_PER_YEAR
  }), RAY_DECIMALS);
};
