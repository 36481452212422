import { API_ETH_MOCK_ADDRESS } from '@yldr/contract-helpers';
import {
  calculateHealthFactorFromBalancesBigUnits,
  USD_DECIMALS,
  valueToBigNumber,
} from '@yldr/math-utils';
import BigNumber from 'bignumber.js';
import React, { useMemo, useState } from 'react';
import { useAssetCaps } from 'src/hooks/useAssetCaps';
import { useModalContext } from 'src/hooks/useModal';
import { useProtocolDataContext } from 'src/hooks/useProtocolDataContext';
import { ERC20TokenType } from 'src/libs/web3-data-provider/Web3Provider';
import { useRootStore } from 'src/store/root';
import { getMaxAmountAvailableToSupply } from 'src/utils/getMaxAmountAvailableToSupply';
import { roundToTokenDecimals } from 'src/utils/utils';

import { useAppDataContext } from '../../../hooks/app-data-provider/useAppDataProvider';
import { AssetInput } from '../AssetInput';
import { ModalWrapperProps } from '../FlowCommons/ModalWrapper';
import { TxSuccessView } from '../FlowCommons/Success';
import {
  DetailsCollateralLine,
  DetailsHFLine,
  DetailsIncentivesLine,
  DetailsNumberLine,
  TxModalDetails,
} from '../FlowCommons/TxModalDetails';
import { getAssetCollateralType } from '../utils';
import { SupplyActions } from './SupplyActions';
import { getSupplyApy } from '../../../utils/getSupplyApy';
import { AlertBanner } from '../../../ui-kit/AlertBanner';
import { CapType } from '../../../components/caps/helper';

export enum ErrorType {
  CAP_REACHED,
}

export const SupplyModalContent = React.memo(
  ({
    underlyingAsset,
    poolReserve,
    userReserve,
    isWrongNetwork,
    nativeBalance,
    tokenBalance,
  }: ModalWrapperProps) => {
    const { marketReferencePriceInUsd, user } = useAppDataContext();
    const { currentNetworkConfig } = useProtocolDataContext();
    const { mainTxState: supplyTxState, gasLimit, txError, setTxError } = useModalContext();
    const { supplyCap: supplyCapUsage } = useAssetCaps();
    const minRemainingBaseTokenBalance = useRootStore(
      (state) => state.poolComputed.minRemainingBaseTokenBalance
    );

    // states
    const [amount, setAmount] = useState('');
    const supplyUnWrapped = underlyingAsset.toLowerCase() === API_ETH_MOCK_ADDRESS.toLowerCase();

    const walletBalance = supplyUnWrapped ? nativeBalance : tokenBalance;

    // const supplyApy = poolReserve.supplyAPY;
    const futureSupplyApy = getSupplyApy({ amountToSupply: amount, tokenToSupply: poolReserve });
    const { supplyCap, totalLiquidity, isFrozen, decimals } =
      poolReserve;

    // Calculate max amount to supply
    const maxAmountToSupply = useMemo(
      () =>
        getMaxAmountAvailableToSupply(
          walletBalance,
          { supplyCap, totalLiquidity, isFrozen, decimals },
          underlyingAsset,
          minRemainingBaseTokenBalance
        ),
      [
        walletBalance,
        supplyCap,
        totalLiquidity,
        isFrozen,
        decimals,
        underlyingAsset,
        minRemainingBaseTokenBalance,
      ]
    );

    const handleChange = (value: string) => {
      if (value === '-1') {
        setAmount(maxAmountToSupply);
      } else {
        const decimalTruncatedValue = roundToTokenDecimals(value, poolReserve.decimals);
        setAmount(decimalTruncatedValue);
      }
    };

    // Calculation of future HF
    const amountIntEth = new BigNumber(amount).multipliedBy(
      poolReserve.formattedPriceInMarketReferenceCurrency
    );
    // TODO: is it correct to ut to -1 if user doesnt exist?
    const amountInUsd = amountIntEth
      .multipliedBy(marketReferencePriceInUsd)
      .shiftedBy(-USD_DECIMALS);
    const totalCollateralMarketReferenceCurrencyAfter = user
      ? valueToBigNumber(user.totalCollateralMarketReferenceCurrency).plus(amountIntEth)
      : '-1';

    const liquidationThresholdAfter = user
      ? valueToBigNumber(user.totalCollateralMarketReferenceCurrency)
          .multipliedBy(user.currentLiquidationThreshold)
          .plus(amountIntEth.multipliedBy(poolReserve.formattedReserveLiquidationThreshold))
          .dividedBy(totalCollateralMarketReferenceCurrencyAfter)
      : '-1';

    const isMaxSelected = amount === maxAmountToSupply;

    let healthFactorAfterDeposit = user ? valueToBigNumber(user.healthFactor) : '-1';

    if (user) {
      healthFactorAfterDeposit = calculateHealthFactorFromBalancesBigUnits({
        collateralBalanceMarketReferenceCurrency: totalCollateralMarketReferenceCurrencyAfter,
        borrowBalanceMarketReferenceCurrency: valueToBigNumber(
          user.totalBorrowsMarketReferenceCurrency
        ),
        currentLiquidationThreshold: liquidationThresholdAfter,
      });
    }

    // ************** Warnings **********

    // token info to add to wallet
    const addToken: ERC20TokenType = {
      address: poolReserve.yTokenAddress,
      symbol: poolReserve.iconSymbol,
      decimals: poolReserve.decimals,
      aToken: true,
    };

    // collateralization state
    const collateralType = getAssetCollateralType(
      userReserve,
    );

    const supplyActionsProps = {
      amountToSupply: amount,
      isWrongNetwork,
      poolAddress: supplyUnWrapped ? API_ETH_MOCK_ADDRESS : poolReserve.underlyingAsset,
      symbol: supplyUnWrapped ? currentNetworkConfig.baseAssetSymbol : poolReserve.symbol,
      blocked: false,
      decimals: poolReserve.decimals,
      isWrappedBaseAsset: poolReserve.isWrappedBaseAsset,
    };

    if (supplyTxState.success)
      return (
        <TxSuccessView
          action="Supplied"
          amount={amount}
          symbol={supplyUnWrapped ? currentNetworkConfig.baseAssetSymbol : poolReserve.symbol}
          addToken={addToken}
        />
      );

    return (
      <>
        {supplyCapUsage.determineWarningDisplay({ supplyCap: supplyCapUsage })}

        <AssetInput
          value={amount}
          onChange={handleChange}
          usdValue={amountInUsd.toString(10)}
          symbol={supplyUnWrapped ? currentNetworkConfig.baseAssetSymbol : poolReserve.symbol}
          assets={[
            {
              balance: maxAmountToSupply,
              symbol: supplyUnWrapped ? currentNetworkConfig.baseAssetSymbol : poolReserve.symbol,
              iconSymbol: supplyUnWrapped
                ? currentNetworkConfig.baseAssetSymbol
                : poolReserve.iconSymbol,
            },
          ]}
          capType={CapType.supplyCap}
          isMaxSelected={isMaxSelected}
          disabled={supplyTxState.loading}
          maxValue={maxAmountToSupply}
          balanceText="Wallet balance"
        />

        <TxModalDetails gasLimit={gasLimit} skipLoad={true} disabled={Number(amount) === 0}>
          <DetailsNumberLine
            description="Supply APY"
            value={poolReserve.supplyAPY}
            futureValue={
              Number(futureSupplyApy).toFixed(4) !== Number(poolReserve.supplyAPY).toFixed(4)
                ? futureSupplyApy
                : undefined
            }
            percent
          />
          <DetailsIncentivesLine
            incentives={poolReserve.aIncentivesData}
            symbol={poolReserve.symbol}
          />
          <DetailsCollateralLine collateralType={collateralType} />
          <DetailsHFLine
            visibleHfChange={!!amount}
            healthFactor={user ? user.healthFactor : '-1'}
            futureHealthFactor={healthFactorAfterDeposit.toString(10)}
          />
        </TxModalDetails>

        {txError && (
          <AlertBanner variant="error" txError={txError} onClose={() => setTxError(undefined)} />
        )}

        <SupplyActions {...supplyActionsProps} />
      </>
    );
  }
);
